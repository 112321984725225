.heading-bordered {
    position: relative;
    //padding-left: 1em;
}

.heading-bordered:before,
.heading-bordered:after {
    position: absolute;
    content: "";
}

.heading-bordered:before {
    left: -10px;
    @include media-breakpoint-up(sm){
    	left: -1em;
    }
    right: auto;
    bottom: 0;
    width: 10px;
    height: 7px;
    background: theme-color("secondary");
    margin: auto;
}

.heading-bordered:after {
	left: calc(-10px + 20px);
	@include media-breakpoint-up(sm){
    	left: calc(-1em + 20px);
    }
    right: auto;
    bottom: 0;
    width: 3em;
    height: 7px;
    background: theme-color("secondary");
    margin: auto;
}

.bordered-above{
	padding-left: 0;
	padding-top: 0.33em;

	&:before{
		text-align: center;
		bottom: auto;
		left: 0;
		top: 0;
		width: 1.5em;
		height: 5px;
	}
}

.bordered-after{
	padding-left: 0;
	padding-bottom: 0.67em;

	&:before{
		top: auto;
		bottom: 0;
		left: auto;
		right: auto;
		width: 1em;
		height: 5px;
	}
}

.bordered-after-center{
	&:before{
		width: 2em;
		left: 50%;
		transform: translate(-50%  ,0);
	}
}
