

//
// IMAGES
//

.img-fit{
	object-fit: cover;
	object-position: 50% 50%;
}

.img-contain{
	object-fit: contain;
}

.overflow-hidden{
	overflow: hidden;
}

.data-image img{
	display: none;
	visibility: hidden;
}

// A background image is absolutely positioned in the parent container
.background-image{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center center;
	&.col-sm-4{
		width: 33.333333%;
	}
 }

.bg-top{
	background-position: top;
	object-position: 50% 0;
}

.bg-fixed{
	background-position: fixed;
}

// BG sizing and positions classes
 .bg-cover{
 	background-size: cover;
 	background-position: center center;
 }


.image-hover{
	&:after{
		position: absolute;
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		content: "";
	}
}


 //
 // ATTENTION BLOCKS
 //

 .image-attention-block{
 	position: absolute;
 	right: 30vw;
 	top: -3em;
 	max-width: 70vw;
 	@include media-breakpoint-up(xl){
 		max-width: 25vw;
 		right: 8rem;
 	}
 }

 .image-attention-block-bottom{
 	top: auto;
 	bottom: -3em;
 }

 .image-attention-block-left{
 	right: auto;
 	left: $spacer;
 	@include media-breakpoint-up(md){
       	left: 8rem;
    }
 }

 .image-attention-block-left-bottom{
 	right: auto;
 	left: 0;
 	@include media-breakpoint-up(lg){
 		left: -3rem;
 	}
 	top: auto;
 	bottom: $spacer;
 }

  .image-attention-block-left-top{
 	right: auto;
 	left: 0;
 	@include media-breakpoint-up(lg){
 		left: -3rem;
 	}
 	bottom: auto;
 	top: $spacer;
 }



.image-overlay{
 	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;

	&-light{
		background-image: linear-gradient(to bottom , fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to bottom right, fade-out(#fff, 0.15) 0%, fade-out(#fff, 0.65) 50%, fade-out(#fff, 1) 67%);
		}
	}
	&-light-vert{
		background-image: linear-gradient(to top , fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to top, fade-out(#fff, 0) 0%, fade-out(#fff, 0.60) 50%, fade-out(#fff, 1) 67%);
		}
	}
	&-light-reversed{
		background-image: linear-gradient(to top , fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(215deg , fade-out(#fff, 0.5), 35% , fade-out(#fff, 1));
		}
	}
	&-dark{
		background: theme-color("brand-primary");
		background: radial-gradient(circle, rgba(102,102,102,0.3) 0%, fade-out(theme-color("primary"),0.3) 100%);
		opacity: 0.4;
	}
	&-dark-vert{
		background-image: linear-gradient(to top , fade-out(theme-color("secondary"), 0.4),55%, fade-out(theme-color("primary"), 0.87));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to bottom, fade-out(theme-color("secondary"), 0.5) 0%, fade-out(theme-color("primary"), 0.65) 50%, fade-out(theme-color("primary"), 0.95) 75%);
		}
	}
 }


.container-narrow{
 .offset-image{
 	@include media-breakpoint-up(xl){
 		margin-right: -18vw;
 		margin-top: -18vw;
 	}
 }
}
