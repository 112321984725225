.navbar-brand{
	width: 120px;
  @include media-breakpoint-up(xl){
    width: 140px;
  }
}


//footer

.footer-brand{
  max-width: 120px;
	max-width: 160px;
}

.navbar-nav .nav-item{
	position: relative;
	font-weight: 600;
}

.under{
  background: theme-color("primary");
  height: 5px;
  position: absolute;
  bottom: 2.2em;
  transition: width 0.1s ease-in-out, left 0.25s ease-in-out;
  &:before{
  	content: '';
  	position: absolute;
  	top: 0;
  	left: -10px;
  	width: 6px;
  	height: 5px;
  	background: theme-color("primary");
  }
  @include media-breakpoint-down(lg){
    display: none;
  }
}


