
// hide the scrollbar

body{
    max-width: 100vw;
    overflow-x: hidden;
}


.main{
    max-width: 100vw;
    overflow-x: hidden;
    min-height: calc(100vh - 8rem); //navbarheight
    z-index: 3;
}

//
// BASIC PAGE GRID
//

// The story's
// The post contents width definition 
// has to be rem for constant width
$max-post-width: 38rem;
$max-element-width: $max-post-width + 18rem;

$article-sidebar-width: $max-post-width / 2.5;


.section{
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 3;
    @include media-breakpoint-up(lg){
      padding-top: 6em;
      padding-bottom: 6em;
    }
}


.container-mw{
    max-width: 1500px;
}

.content-container{
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}





.container-narrow{
    @include media-breakpoint-down(lg){
        padding-left: 15px;
        padding-right: 15px;   
    }
    @include media-breakpoint-up(sm){
        margin-left: 8vw;
        margin-right: 8vw;
    }

    @include media-breakpoint-up(xl){
        margin-left: 12vw;
        margin-right: 12vw;
    }
     @include media-breakpoint-up(s-xl){
        margin-left: 18vw;
        margin-right: 18vw;
    }

    &.to-edge.to-edge-right{
      @include media-breakpoint-up(sm){
        margin-right: 0; // container-narrow 12rem + 2 rem padding
      }
    }

    &.to-edge.to-edge-left{
      @include media-breakpoint-up(sm){
        margin-left: 0; // container-narrow 12rem + 2 rem padding
      }
    }

    .to-edge.to-edge-right{
      @include media-breakpoint-up(xl){
        margin-right: -18vw; // container-narrow 12rem + 2 rem padding
      }
    }

    .to-edge.to-edge-left{
      @include media-breakpoint-up(xl){
        margin-left: -18vw; // container-narrow 12rem + 2 rem padding
      }
    }
}

//
// CONTAINER WIDE
// A full width container with generous padding on the outside

.container-wide{
    max-width: 100vw;
    @include media-breakpoint-down(lg){
        padding-left: 15px;
        padding-right: 15px;  
    }
    @include media-breakpoint-up(lg){
        margin-left: 6vw;
        margin-right: 6vw;
    }
}


.container-wide.to-edge-left .container-narrow{
    @include media-breakpoint-up(xl){
        margin-right: 6vw;
    }
    @include media-breakpoint-up(s-xl){
        margin-right: 8vw;
    }
}


//
// CONTAINER EFFECTS
// offsets and such

.offset-container-effect{
        padding-top: 6em; // because of no more section on negative top offset
        padding-bottom: 6em;
    @include media-breakpoint-up(s-xl){
        margin-right: 8vw;
        margin-top: -15em; // negative margin of 

        .container-wide{
            margin-right: 4vw; // calculations based on margins on original container
        }

        .container-narrow{
            margin-right: 8vw;
        }
    }
}

.overflow-container{
    overflow: visible !important;
    -webkit-overflow-scrolling: touch;
}


//
// STORIES
//



.story{
    h1,h2,h3,h4,h5,h6,p,
    ul,dl,ol,span, .zero-clipboard,
    .card{
        @include media-breakpoint-up(sm) {
            max-width: $max-post-width; 
            margin-left: 0;
            margin-right: auto;
        }
    }
    blockquote{
        max-width: $max-element-width;
        text-align: center;
        margin-left: 0;
        margin-right: auto;
        h1,h2,h3,h4,h5,h6
        ul, dl, ol{
            max-width: $max-element-width; //avoid these overrides
        }
    }

    .breadcrumb{
        max-width: none;
    }

    .the-content{
        position: relative;
        @include media-breakpoint-up(sm) {
            margin-left: auto;
            margin-right: 0;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 0;
            padding-right: 0;
            background: #fff;
            border-radius: 3px;
        }
    }

    .post-image-container,
    iframe{
        max-width: $max-element-width !important;
        margin: 3em auto !important;
        img{
            @include img-fluid;
        }
    }
}

.full-width{
    h1,h2,h3,h4,h5,h6,p,
    ul,dl,ol,span, .zero-clipboard,
    .card{
        @include media-breakpoint-up(sm) {
            max-width: none; 
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.post-content{
    background: transparent;
}


//
// Story styles
//

.story-centered{
    h1,h2,h3,h4,h5,h6,p,
    ul,dl,ol,span, .zero-clipboard,
    .card{
        @include media-breakpoint-up(sm) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}







//mixin


//
// VERTICAL CENTERING
//

.vert-center-lg-me{
    @include media-breakpoint-up(lg){
        top: 50% !important;
        transform: translate(0,-50%);
        height: auto !important;
    }
}




//
// ASPECT RATIO'S
//

$aspects: () !default;
$aspects: (
    '24-9': 9/24,
    '21-9': 9/21,
    '16-9': 9/16,
    '3-2': 2/3,
    '2-1': 1/2,
    '1-1': 1/1,
    '1-2': 2/1,
    '1-15': 1.5/1,
    '2-3': 3/2,
);

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $name, $aspect in $aspects {

            .aspect-ratio#{$infix}-#{$name} { 
                position: relative;
                &:before{
                    display: block;
                    content: " ";
                    width: 100%;
                    padding-top: ($aspect) * 100%;
                }
            }

        }
    }
}


//
// POSTITIONING ATTRIBUTES
//
.pos-abs{
    position: absolute !important;
}

.pos-lg-abs{
    @include media-breakpoint-up(lg){
        position: absolute !important;
        top: 0;
        &.container-fluid{
            width: 100%;
        }
    }
}

.pos-rel{
    position: relative !important;
}

.pos-lg-rel{
    @include media-breakpoint-up(lg){
        position: relative !important;

    }
}



//
// UTILS
//

.justify-self-start{
    justify-self: start;
}



.z-0 { z-index: 0; }
.z-1 { z-index: 1; }
.z-2 { z-index: 2; }
.z-3 { z-index: 3; }
.z-4 { z-index: 4; }
.z-5 { z-index: 5; }


//
// VH HEIGHTS
//
.vh-100{
    min-height: 100vh;
}

.vh-50{
    min-height: 50vh;
}

.vh-25{
    min-height: 25vh;
}



//
// OVERFLOWING THE GRID
//


.overflow-visible{
    overflow: visible !important;
}

.overflow-x-hidden{
    max-width: 100vw;
    overflow-x: hidden !important;
}



//
// SOME BRAND ELEMENTS
//


.flex-clip{
  -webkit-clip-path: url("/images/flex-shape.svg");
  clip-path: url("/images/flex-shape.svg");  
}


.bleizo-shape{
    background: url("/images/bleizo-shape-faded-pink.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    left: 6vw;
    right: auto;
    top: -20%;
    width: 40vw;
    height: 120%;
    z-index: 4;
    opacity: 0.65;
}



.flex-shape-blue{
    background-image: url("/images/flex-shape-blue-light.svg");
}

footer .flex-shape-blue{
    opacity: 0.2;
    z-index: 0;
}


.homepage-header-image{
    position: relative;
    min-height: 90vh;
}

.homepage-header-group{
    top: 30%;
    right: 15px;
    transform: translateY(-50%);
    text-align: right;
     h1{
            font-size: 2.5em;
        }
    @include media-breakpoint-up(sm){
       h1{
            font-size: 4em;
        }
        right: 6vw;
    }
    .display-3{
        line-height: 1;
    }

    @include media-breakpoint-up(lg){
        right: 15vw;
        left: auto;
        top: 20%;
        font-size: 110%;
        text-align: right;
    }
     @include media-breakpoint-up(xl){
       right: 18vw;
       h1{
        font-size: 4.6em;
       }
    }
}

.bg-faded-offset{
    position: absolute;
    background: theme-color("primary");
    top: 20%;
    left: 0;
    height: 80%;
    width: 100%;
    opacity: 0.075;
    z-index: 0;
}

.bg-faded-offset-green{
    position: absolute;
    background: theme-color("secondary");
    top: 20%;
    left: 0;
    height: 80%;
    width: 100%;
    opacity: 0.075;
    z-index: 0;
}


#google-container {
    position: relative;
    width: 100%;
    height: 500px;
}

#locatie {
    position: relative;
    address {
        position: relative;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 1em 1em;
        color: theme-color("primary");
        text-align: center;
        a {
            margin-left: 1em;
        }
    }
}