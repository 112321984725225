.transition-fade {
    transition: .4s;
    opacity: 1;
}
html.is-animating .transition-fade{
    opacity: 0;
}



.hover-underline {
  @include media-breakpoint-up(lg){
    position: relative;
    text-decoration: none;
    padding-bottom: 0.45em;
    font-weight: 700;
    
    &:hover {
      text-decoration: none;
      &:before {
        width: 100%;
        left: 0;
        transition: 0.3s;
      }
    }
    
    &:before {
      content: "";
      width: 0;
      height: 4px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: theme-color(primary);
      transition: 0.3s ease-in-out;
    }
  }
}