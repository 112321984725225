html {
  font-size: 105%;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  line-height: 1.75;
}

// mixin for light text on dark background font rendering
@mixin dark-bg {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//
// REDEFINE TYPE BASED ON SCREEN WIDTH
// these are type styles... Bigger screens bigger base size

@include media-breakpoint-up(sm) {
  body {
    font-size: 108%;
  }
}

@media screen and (min-width: 1500px) {
  body {
    font-size: 115%;
  }
}

@media screen and (min-width: 1700px) {
  body {
    font-size: 120%;
  }
}

//
// FONT FEATURES
//

h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-smoothing: antialiased;
  /* Use with caution */
  -moz-font-feature-settings: "kern", "liga" 1;
  -moz-font-feature-settings: "kern=1";
  -ms-font-feature-settings: "kern", "liga" 1;
  -o-font-feature-settings: "kern", "liga" 1;
  -webkit-font-feature-settings: "kern", "liga" 1;
  font-feature-settings: "kern", "liga" 1;
}

h1,
h2,
h3 {
  @include media-breakpoint-down(sm) {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  letter-spacing: -0.03em;
}

p {
  font-weight: 400;
  letter-spacing: -0.015em;
}

//// UTILITIES ////
//
// MEASURE UTILITY
//

$measure: 30em !default;
$measure-narrow: 20em !default;
$measure-wide: 34em !default;
$measure-ultra-wide: 42em !default;

/* Measure is limited to ~66 characters */
.measure {
  max-width: $measure;
}

/* Measure is limited to ~80 characters */
.measure-wide {
  max-width: $measure-wide;
}

/* Measure is limited to ~80 characters */
.measure-ultra-wide {
  max-width: $measure-ultra-wide;
}

/* Measure is limited to ~45 characters */
.measure-narrow {
  max-width: $measure-narrow;
}

//
// LETTER SPACING
//

$letter-spacing-tight: -0.05em !default;
$letter-spacing-1: 0.08em !default;
$letter-spacing-2: 0.15em !default;

.lh-solid {
  line-height: 1.1;
}
.lh-title {
  line-height: 1.2;
}
.lh-copy {
  line-height: 1.5;
}

.tracked {
  letter-spacing: $letter-spacing-1;
}

.tracked-tight {
  letter-spacing: $letter-spacing-tight;
}

.tracked-mega {
  letter-spacing: $letter-spacing-2;
}

//
// SOLD BRAND TYPE EFFECTS
//

.brand-dot {
  &:after {
    content: ".";
    color: theme-color("primary");
  }
}

.dropcap {
  &::first-letter {
    float: left;
    font-size: 5em;
    line-height: 1em;
    margin-top: -0.05em;
    margin-bottom: -0.15em;
    padding-right: 0.05em;
    padding-left: 0;
    font-weight: 400;
    text-transform: uppercase;
    color: theme-color("primary");
  }
}

.columns {
  @include media-breakpoint-up(sm) {
    columns: 2;
  }
}

// first paragraph is lead
.article-content > p:nth-of-type(1) {
  font-size: 1.3em;
  letter-spacing: -0.01em;
  line-height: 1.5;
  margin-bottom: 3rem;
  &::first-letter {
    float: left;
    font-size: 3em;
    line-height: 1em;
    margin-top: -0.05em;
    margin-bottom: -0.15em;
    padding-right: 0.05em;
    padding-left: 0;
    font-weight: 300;
    text-transform: uppercase;
  }
}

//
// BADGES
//

.badge.bleizo {
  background-color: theme-color("primary");
}

.badge.hoefweg {
  background-color: theme-color("secondary");
}

.badge.vergadering {
  background-color: theme-color("info");
}

//
// ATTENTION BLOCK POST OVERVIEW
//

.post-attention-block {
  position: absolute;
  right: 10%;
  bottom: -0.8em;
  max-width: 60vw;
  @include media-breakpoint-up(xl) {
    max-width: 25vw;
    top: -0.8em;
    bottom: auto;
  }
}

.bg-light {
  .nav-link,
  .btn-outline-primary {
    color: darken(theme-color("primary"), 5%);
  }
  .btn-outline-primary:hover {
    color: white;
  }
}
